import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { cartAction } from "../../Redux/Actions/index";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import "../Footer/footer.scss";

const Footer = (props) => {
  let dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.cartReducer.cartItems);
  const [removeItemModel, setRemoveItmeModel] = useState(false);
  const [itemToRemove, setItemToRemove] = useState({});
  const cartReducer = useSelector((state) => state.cartReducer);

  useEffect(() => {
    dispatch(cartAction.getCartItems());
  }, []);

  const handleQuantityChange = async (item, incmType) => {
    if (incmType === "plus") {
      if (item.selectedQuantity < item.quantity) {
        await dispatch(cartAction.changeQuantity(item, 1, incmType));
        await dispatch(cartAction.getCartItems());
      }
    } else {
      if (item.selectedQuantity > 1) {
        await dispatch(cartAction.changeQuantity(item, 1, incmType));
        await dispatch(cartAction.getCartItems());
      }
    }
  };

  return (
    <div className="footer-main">
      <footer className="footer">
        <div className="footer-resize w-75">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="footer-list">
                  <h2>About Company</h2>
                  <p>
                    We deliver almost 30 years of excellent service in consumer testing. We are
                    passionate about Science and caring for others.
                  </p>
                  <div className="footer-icon">
                    <ul>
                      <li>
                        <span>
                          <a href={"https://www.facebook.com/Accugenedx/"} target="_blank">
                            <i class="fab fa-facebook-square"></i>
                          </a>
                        </span>
                      </li>
                      <li>
                        <span>
                           <a href={"https://www.instagram.com/accugenedx/"} target="_blank">
                            <i className="fab fa-instagram"></i>
                           </a>
                        </span>
                      </li>
                      <li>
                        <span>
                           <a href={"https://twitter.com/accugenedx"} target="_blank">
                            <i className="fab fa-twitter"></i>
                           </a>
                        </span>
                      </li>
                      <li>
                        <span>
                           <a href={"https://www.linkedin.com/company/accugenedx-1"} target="_blank">
                            <i class="fab fa-linkedin"></i>
                           </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="footer-list links">
                  <h2>Useful Links</h2>
                  <ul className={"border-white"}>
                    <li>
                      <Link to="/page/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/profile">My Account</Link>
                    </li>
                    <li>
                      <Link to="/page/return-policy">Return policy</Link>
                    </li>
                    <li>
                      <Link to="/page/privacy-policy">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/support">Support</Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="col-md-4">
                <div className="footer-list">
                  <h2>Product</h2>
                  <ul>
                    <li>Product 01</li>
                    <li>Product 02</li>
                    <li>Product 03</li>
                    <li>Product 04</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      <div className="footer-sub p-1">
        <p>© {new Date().getFullYear()} Accugenedx. All rights reserved</p>
      </div>

      {/* modal */}

      <div className="modal" id="cart-inner">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Your cart</h4>
              <button type="button" class="close" data-dismiss="modal">
                <span>
                  <i class="far fa-times-circle"></i>
                </span>
              </button>
            </div>

            <div className="modal-body">
              {cartDetails?.length > 0
                ? cartDetails?.map((cartData, i) => {
                  // console.log("cartDetails", cartDetails)
                  return (
                    <div className="cart-item" key={i}>
                      <div className={"pr-2"}>0
0                         <p style={{ marginBottom: 0, fontWeight: 600 }} className={"d-flex"}>
                          {cartData?.name} &nbsp;
                        </p>
                        <div className="item-img">
                          <img
                            src={cartData?.image_name}
                            alt={cartData?.image_name}
                          />
                        </div>
                        {cartData?.subscription === true ? (
                          <p style={{ color: "green", textAlign: "center" }}>
                            {cartData?.subscriptionDuration}
                          </p>
                        ) : null}
                      </div>
                      <div className="cart-qty">
                        <div className={"d-flex justify-content-between"}>
                          <label>Quantity</label>
                          <label>
                            <i
                                onClick={() => {
                                  setItemToRemove(cartData);
                                  setRemoveItmeModel(true);
                                }}
                                className="fas fa-trash"
                            ></i>
                          </label>
                        </div>

                        <div className="quantity-btn-sub">
                          <button
                            onClick={() =>
                              handleQuantityChange(cartData, "minus")
                            }
                          >
                            <span>
                              <i class="fas fa-minus"></i>
                            </span>
                          </button>
                          <input
                            type="number"
                            placeholder={cartData.selectedQuantity}
                            disabled
                            readOnly
                          />
                          <button
                            onClick={() =>
                              handleQuantityChange(cartData, "plus")
                            }
                          >
                            <span>
                              <i class="fas fa-plus"></i>
                            </span>
                          </button>
                        </div>
                        <h4 className={"total"}>${cartData?.price?.toFixed(2)}</h4>
                      </div>
                    </div>
                  );
                })
                : "No Item added"}

              {cartReducer.subTotal !== "0.00" && (
                <div className="subtotal-div">
                  <p>Subtotal</p>
                  <p>${cartReducer.subTotal}</p>
                </div>
              )}
              {cartDetails?.length > 0 ? (
                <div className="cart-btn-group">
                  <button
                    className="red-btn"
                    data-dismiss="modal"
                    onClick={() => {
                      props.history.push("/cart");
                    }}
                  >
                    View Cart
                  </button>
                  <button
                    className="white-btn"
                    data-dismiss="modal"
                    onClick={() => {
                      if (localStorage.getItem("token")) {
                        props.history.push("/shipping");
                      } else {
                        props.history.push("/signin");
                      }
                    }}
                  >
                    Check Out
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {removeItemModel === true ? (
        <div className="modal" id="removeItem" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {" "}
                  Are you sure to remove this item?
                </h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  onClick={(e) => setRemoveItmeModel(false)}
                >
                  <span>
                    <i class="far fa-times-circle"></i>
                  </span>
                </button>
              </div>

              <div
                className="modal-body"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  className="red-btn"
                  onClick={(e) => {
                    dispatch(cartAction.removeItemFromCart(itemToRemove));
                    setRemoveItmeModel(false);
                  }}
                >
                  Yes
                </button>
                <button
                  className="red-btn"
                  onClick={(e) => setRemoveItmeModel(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="modal" id="change-inner">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Change Password</h4>
              <button type="button" class="close" data-dismiss="modal">
                <span>
                  <i class="far fa-times-circle"></i>
                </span>
              </button>
            </div>

            <div className="modal-body">
              <form className="change-form">
                <div class="form-group">
                  <label htmlFor="email">Old Password</label>
                  <input type="password" class="form-control" />
                </div>
                <div class="form-group">
                  <label htmlFor="email">New Password</label>
                  <input type="password" class="form-control" />
                </div>
                <div class="form-group">
                  <label htmlFor="email">Confirm Password</label>
                  <input type="password" class="form-control" />
                </div>
                <div className="text-right">
                  <button className="red-btn">Save Changes</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="edit-inner">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Address</h4>
              <button type="button" class="close" data-dismiss="modal">
                <span>
                  <i class="far fa-times-circle"></i>
                </span>
              </button>
            </div>

            <div className="modal-body">
              <form className="change-form">
                <div class="form-group">
                  <label htmlFor="email">Full Name</label>
                  <input type="text" class="form-control" />
                </div>
                <div class="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input type="email " class="form-control" />
                </div>
                <div class="form-group">
                  <label htmlFor="email">Phone Number</label>
                  <input type="tel" class="form-control" />
                </div>
                <div class="form-group">
                  <label htmlFor="email">Address Line 2</label>
                  <input type="text" class="form-control" />
                </div>
                <div className="row">
                  <div class="form-group col-md-6">
                    <label htmlFor="email">City</label>
                    <input type="text" class="form-control" />
                  </div>
                  <div class="form-group col-md-6">
                    <label htmlFor="email">State</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="form-group">
                  <label htmlFor="email">Country</label>
                  <input type="text" class="form-control" />
                </div>
                <div className="text-right">
                  <button className="red-btn">Save Changes</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Footer);
